import { CarViewModel, IImage, ILink } from '..';
import { IFacets, IFilterItemViewModel } from './filter.interface';

export interface IAmountUsedCarsResponse {
    readonly amount: number;
    readonly makes: Record<string, number>;
}

export interface IFilterSearchResultViewModel {
    total?: number;
    facets?: IFacets;
    premiumText?: Record<string, string | null>;
    premiumTextDefault?: string;
    hits?: IFilterItemViewModel[];
}

export enum ProductLeasingType {
    noLeasing = 0,
    businessFinancial = 1,
    businessOperational = 2,
    privateFinancial = 3,
    privateOperational = 4,
}

export interface IProductViewModel {
    title: string;
    variant: string;
    priceType: string;
    leasingPrice?: string;
    leasingDownPayment?: string;
    leasingDuration?: number;
    leasingYearlyMileage?: string;
    leasingIncludesInsurance: boolean;
    leasingServiceAndMaintenance?: string;
    leasingResidualValue?: string;
    leasingTotalPayment?: string;
    leasingType: ProductLeasingType;
    cashPrice?: string;
    greenTax?: string;
    mileage?: string;
    motor?: string;
    properties: CarViewModel;
}

export interface IProductUspViewModel {
    icon?: IImage;
    title?: string;
    teaser?: string;
    link?: ILink;
}
