import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IVideoItem } from '@ncg/data';

@Component({
    selector: 'ncg-video-item',
    template: `
        <div class="rte-content">
            <h3
                *ngIf="item.title"
                class="video-item__title"
                [ngClass]="{
                    'mb-4': item.text,
                    'mb-5': !item.text
                }"
            >
                {{ item.title }}
            </h3>
            <div *ngIf="item.text" class="image-text-item-text" [ngClass]="{ 'mb-3': item.link, 'mt-5': !item.title }">
                <ncg-rich-text [html]="item.text"></ncg-rich-text>
            </div>
        </div>
        <ncg-button *ngIf="item.link" [title]="item.link.name" [buttonClass]="'button is-secondary'" [link]="item.link"></ncg-button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoItemComponent {
    @Input() item: IVideoItem;
}
