import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationModule, SearchIconModule } from '@ncg/ui';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { HeaderComponent } from './header/header.component';

@NgModule({
    declarations: [HeaderComponent],
    imports: [CommonModule, NavigationModule, RouterModule, TranslateModule.forChild(), IconSpriteModule, SearchIconModule],
    exports: [HeaderComponent],
})
export class LayoutModule {}
