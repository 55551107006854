import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IImage, IImageTextItem, ImageTextPickerSpot } from '@ncg/data';
import { Subject, takeUntil } from 'rxjs';

import { HdAspect } from '../../../utils/helpers/aspect-ratio';
import { ImageUrl } from '../../../utils/helpers/image-helper';
import { TrackingService } from '../../../core/tracking.service';

@Component({
    selector: 'ncg-image-text-item',
    template: `
        <div
            *ngIf="singleSlide; else multiSlide"
            class="image-text-item"
            [ngStyle]="{
                'flex-direction': this.invert ? 'row-reverse' : ''
            }"
        >
            <ng-container *ngTemplateOutlet="multiSlide"></ng-container>
        </div>
        <ng-template #multiSlide>
            <div *ngIf="showMedia" [ngStyle]="{ 'align-self': singleImagePosition }">
                <a
                    *ngIf="!item.embed && item.link && item.link.url; else mediaTmpl"
                    [href]="item.link.url"
                    [target]="item.link.isExternal ? '_blank' : ''"
                    [rel]="item.link.isExternal ? 'noopener' : ''"
                    ncgInterceptLinks
                >
                    <ng-container *ngTemplateOutlet="mediaTmpl"></ng-container>
                </a>

                <ng-template #mediaTmpl>
                    <ncg-embed
                        *ngIf="item.embed; else imageTmpl"
                        [thumbnail]="item.embed.thumbnail"
                        [embedUrl]="item.embed.embedUrl"
                        [embedCaption]="item.embed.embedCaption"
                        [autoplay]="item.embed.autoplay"
                    ></ncg-embed>
                </ng-template>

                <ng-template #imageTmpl>
                    <picture [ngClass]="imageClass" *ngIf="pictureSrc" data-chromatic="ignore">
                        <source media="(min-width: 768px)" [srcset]="imageSrcSet" />
                        <source media="(max-width: 767px)" [srcset]="mobileImageSrcSet" />
                        <img [src]="pictureSrc" [srcset]="imageSrcSet" [alt]="pictureAlt" loading="lazy" ncgImageLoad />
                    </picture>
                </ng-template>
            </div>
            <div
                class="image-text-item__content"
                *ngIf="hasText && item"
                [ngClass]="{
                    'image-text-item__content--single-slide': singleSlide
                }"
                [ngStyle]="{
                    'align-self': singleContentPosition,
                    'justify-content': alignCenter ? 'center' : '',
                    'text-align': alignCenter ? 'center' : ''
                }"
            >
                <div class="rte-content">
                    <ncg-spot-headline
                        *ngIf="item.title"
                        [level]="headingLevel"
                        [headlineClass]="'image-text-item-title'"
                        [headlineNgClass]="{
                            'image-text-item-title--three-four': count >= 3,
                            'mb-4': item.text,
                            'mb-5': !item.text
                        }"
                    >
                        <span [innerHTML]="item.title | safe: 'html'"></span>
                    </ncg-spot-headline>
                    <div class="image-text-item-text" [ngClass]="{ 'mb-3': item.link, 'mt-5': !item.title }" *ngIf="item.text">
                        <ncg-rich-text [html]="item.text"></ncg-rich-text>
                    </div>
                </div>
                <ncg-button
                    *ngIf="item.link && item.showButton"
                    [title]="item.link?.name"
                    [buttonClass]="buttonClass"
                    [link]="item?.link"
                ></ncg-button>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageTextItemComponent implements OnInit, OnDestroy {
    @Input() count: number;
    @Input() item: IImageTextItem;
    @Input() showMedia?: boolean;
    @Input() hasText?: boolean;
    @Input() headingLevel: '2' | '3';
    @Input() slidesPerView: number;
    @Input() invert: ImageTextPickerSpot['invert'];
    @Input() alignCenter: ImageTextPickerSpot['alignCenter'];
    singleImagePosition: string;
    singleContentPosition: string;
    pictureSrc = '';
    mobileImageSrcSet: string = '';
    imageSrcSet: string = '';
    pictureAlt = '';
    aspectRatio = HdAspect;
    width = 596;
    imageClass: string[] = [];
    isVideoAllowed = false;
    singleSlide = false;
    buttonClass: string = '';

    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly trackingService: TrackingService,
        private readonly cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.trackingService
            .marketingConsentGiven()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((consent) => {
                this.isVideoAllowed = consent;
                this.cd.markForCheck();
            });

        if (this.item.image) {
            this.pictureAlt = this.item.title || this.item.image.altText || '';
        }

        if (this.item.link) {
            this.imageClass.push('animate-mask is-x-small-mask');
        }

        if (this.showMedia && this.item && this.item.image) {
            this.generateSrcSet(this.item.image);
            this.generateImageSrc(this.item.image);
        }

        if (this.slidesPerView === 1 && this.hasText && this.item && this.showMedia && this.pictureSrc) {
            this.singleSlide = true;

            // only support alignment for `singleSlide` as
            // other alignments is happening in ImageTextPickerSpotComponent
            this.singleImagePosition = this.contentAlignment(this.item.imagePosition ?? 'top');
            this.singleContentPosition = this.contentAlignment(this.item.contentPosition ?? 'center');
        }

        this.buttonClass = this.getButtonClasses(this.item.buttonAppearance);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private getButtonClasses(appearance: IImageTextItem['buttonAppearance']): string {
        switch (appearance) {
            case 'filled':
                return 'button is-filled';
            case 'filledExternal':
                return 'button is-filled is-external';
            case 'outlineExternal':
                return 'button is-outline is-external';
            case 'arrow':
                return 'button is-arrow';
            case 'outline':
            default:
                return 'button is-outline';
        }
    }

    private contentAlignment(contentPosition: IImageTextItem['contentPosition']) {
        switch (contentPosition) {
            case 'center':
                return 'center';
            case 'bottom':
                return 'flex-end';
            default:
                return 'flex-start';
        }
    }

    private generateSrcSet(image: IImage) {
        const mobileWidth = 720;

        if (this.count === 1) {
            this.aspectRatio = 0.6060606061; // custom aspect
            this.imageClass.push('is-ratio-image-text-single');
        } else {
            this.imageClass.push('is-ratio-hd');
        }

        if (this.count === 3) {
            this.width = 387;
        } else if (this.count === 4) {
            this.width = 283;
        }

        this.mobileImageSrcSet = `
            ${ImageUrl(image, { width: mobileWidth, heightratio: this.aspectRatio, mode: 'crop' })} 1x,
            ${ImageUrl(image, { width: mobileWidth * 2, heightratio: this.aspectRatio, mode: 'crop' })} 2x
            `;

        this.imageSrcSet = `
            ${ImageUrl(image, { width: this.width, heightratio: this.aspectRatio, mode: 'crop' })} 1x,
            ${ImageUrl(image, { width: this.width * 2, heightratio: this.aspectRatio, mode: 'crop' })} 2x
            `;
    }

    private generateImageSrc(image: IImage) {
        this.pictureSrc = ImageUrl(image, { width: this.width, heightratio: this.aspectRatio, mode: 'crop' });
    }
}
