import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ILink } from '@ncg/data';

@Component({
    selector: 'ncg-button',
    template: `
        <a
            *ngIf="url; else noLinkTmpl"
            [href]="url"
            [title]="title"
            [target]="isExternal ? '_blank' : ''"
            [rel]="isExternal ? 'noopener' : ''"
            class="button"
            [ngClass]="buttonClass"
            ncgInterceptLinks
        >
            {{ title }}
            <ng-content></ng-content>
        </a>

        <ng-template #noLinkTmpl>
            <button [type]="buttonType" [title]="title" class="button" [ngClass]="buttonClass" [disabled]="disabled">
                {{ title }}
                <ng-content></ng-content>
            </button>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
    @Input() link?: ILink;
    @Input() isSecondary?: boolean;
    @Input() title?: string;
    @Input() queryParams?: Record<string, unknown>;
    @Input() buttonType = 'button';
    @Input() disabled = false;
    @Input() buttonClass: string;
    @Input() isLarge?: boolean;
    @Input() isSmall?: boolean;

    url?: string;
    isExternal = false;

    constructor(private readonly router: Router) {}

    ngOnInit() {
        if (this.link?.url) {
            if (this.queryParams && !this.link.isExternal) {
                const fullUrl = this.router.createUrlTree([this.link.url], { queryParams: this.queryParams });
                this.url = fullUrl.toString();
            } else {
                this.url = this.link.url;
            }

            if (!this.title && this.link.name) {
                this.title = this.link.name;
            }

            this.isExternal = this.link.isExternal;
        }
        this.buttonClass = this.buttonClass ? this.buttonClass : this.setButtonClass();
        if (this.isLarge) {
            this.buttonClass += ' is-large';
        } else if (this.isSmall) {
            this.buttonClass += ' is-small';
        }
    }

    private setButtonClass() {
        return this.isSecondary ? 'is-secondary' : 'is-primary';
    }
}
